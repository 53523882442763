import Link from 'next/link';
import {FormattedMessage, useIntl} from 'react-intl';

import {Col} from '@components/Col';
import {HeroIntro} from '@components/HeroIntro';
import {Row} from '@components/Row';
import {Typography} from '@components/Typography';

import {Search} from '@pageComponents/HomePage/Search';
import bgImg from 'images/homepage/HP-banner-nemovitosti-prehledne-desktop.png';
import bgImgMobile from 'images/homepage/HP-banner-nemovitosti-prehledne-mobile.png';
import {HeroIntroBackroundMD} from '@liveComponents/BackgroundImage/HeroIntroBackroundMD';
import {getLocalizedRoute, routes} from '@utils/routes';
import {LocaleEnum, LocaleType} from 'src/types/general';

type IntroLegacyProps = {
    locale: LocaleType;
};

export const IntroLegacy = ({locale}: IntroLegacyProps) => {
    const intl = useIntl();

    return (
        <HeroIntro>
            <HeroIntroBackroundMD
                bgImg={bgImg}
                bgImgMobile={bgImgMobile}
                alt={intl.formatMessage({defaultMessage: 'Nabídněte nemovitost'})}
                gradient={locale === LocaleEnum.sk}
            />

            <HeroIntro.Content>
                <HeroIntro.Top>
                    <Search />
                </HeroIntro.Top>

                <HeroIntro.Bottom>
                    <HeroIntro.Heading variant="h1">
                        <FormattedMessage defaultMessage="Nemovitosti, které jinde nenajdete" />
                    </HeroIntro.Heading>

                    <Row className="justify-content-between align-items-center gy-8">
                        <Col xs="auto">
                            <Typography as="p" variant="h4" className="mb-0">
                                <FormattedMessage defaultMessage="S hlídacím psem najdete vysněný domov rychleji." />
                            </Typography>
                        </Col>

                        <Col xs={12} md="auto">
                            <Link href={getLocalizedRoute(routes.serviceCentreWatchdog, locale)} passHref legacyBehavior>
                                <HeroIntro.PromoButton>
                                    <FormattedMessage defaultMessage="Nastavit hlídacího psa" />
                                </HeroIntro.PromoButton>
                            </Link>
                        </Col>
                    </Row>
                </HeroIntro.Bottom>
            </HeroIntro.Content>
        </HeroIntro>
    );
};
