import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './OfferCardsGrid.module.scss';
import { OfferCardsGridItem } from './OfferCardsGridItem';

export const OfferCardsGrid: FC<React.PropsWithChildren> = ({ children }) => {
	return <ul className={classNames(styles.offerCardsGrid, 'offerCardsGrid')}>{children}</ul>;
};

export default Object.assign(OfferCardsGrid, {
	Item: OfferCardsGridItem,
});
