import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './OfferCard.module.scss';
import { OfferCardAddress } from './OfferCardAddress';
import { OfferCardContent } from './OfferCardContent';
import { OfferCardFeatures } from './OfferCardFeatures';
import { OfferCardImage } from './OfferCardImage';
import { OfferCardLabel } from './OfferCardLabel';
import { OfferCardPrice } from './OfferCardPrice';

export const OfferCard: FC<React.PropsWithChildren> = ({ children }) => {
	return <article className={classNames(styles.offerCard, 'offerCard')}>{children}</article>;
};

export default Object.assign(OfferCard, {
	Address: OfferCardAddress,
	Content: OfferCardContent,
	Features: OfferCardFeatures,
	Image: OfferCardImage,
	Label: OfferCardLabel,
	Price: OfferCardPrice,
});
