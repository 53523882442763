import {OfferCard} from '@components/OfferCard';
import {PropertyPrice} from '@components/PropertyPrice';
import {FeaturesList} from '@components/FeaturesList';
import {AdvertImportType, Currency, Disposition, EstateType, OfferType} from '.cache/__types__';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {translations} from '@constants/Disposition';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import NextImage from 'next/image';
import NoImagePlaceholder from 'images/default_foto_small.jpg';
import {useLocale} from '@utils/locale';
import {Divider} from '@components/Divider';
import {advertPlaceholder} from '@constants/Image';
import {OfferEstateTypeTranslation, offerEstateTypeImperativeTranslation} from '@constants/OfferType';
import {getBedrooms, getSurfaceOrBedroomTitle} from '@pageComponents/Detail/utils';

export type AdvertOfferCardProps = {
    imageUrl?: string;
    imageAlt?: string;
    surface?: number;
    surfaceLand?: number;
    disposition?: Disposition;
    estateType: EstateType;
    offerType: OfferType;
    address: string;
    price: number;
    charges?: number;
    currency: Currency;
    uri: string;
    discounted: boolean;
    originalPrice: number;
    type?: AdvertImportType;
    dataJson?: string;
};

export const AdvertOfferCard = ({
    imageUrl,
    imageAlt,
    surface,
    surfaceLand,
    disposition,
    estateType,
    offerType,
    address,
    price,
    charges,
    currency,
    uri,
    discounted,
    originalPrice,
    type,
    dataJson,
}: AdvertOfferCardProps) => {
    const locale = useLocale();
    const intl = useIntl();

    const alt = imageAlt ?? offerEstateTypeImperativeTranslation(intl, offerType, estateType);

    const bedrooms = getBedrooms(type, dataJson);
    const surOrBedTitle = getSurfaceOrBedroomTitle(surface ?? 0, bedrooms);

    return (
        <OfferCard>
            <OfferCard.Image>
                <NextImage
                    src={!!imageUrl && imageUrl !== '' ? imageUrl : NoImagePlaceholder}
                    alt={alt}
                    title={alt}
                    placeholder="blur"
                    blurDataURL={!!imageUrl ? advertPlaceholder : undefined}
                    fill
                    sizes="600px"
                    style={{
                        objectFit: 'cover',
                    }}
                />
            </OfferCard.Image>

            <OfferCard.Content>
                <Link
                    href={getLocalizedRoute(routes.detail, locale, {
                        slug: uri,
                    })}
                >
                    <OfferCard.Label>
                        <OfferEstateTypeTranslation offerType={offerType} estateType={estateType} />
                    </OfferCard.Label>
                    <OfferCard.Address>{address}</OfferCard.Address>
                </Link>

                <Divider color="grey-light" className="my-3" />

                <OfferCard.Features>
                    <FeaturesList>
                        {disposition && disposition !== Disposition.Undefined && (
                            <FeaturesList.Item iconName="FloorPlan">{translations[disposition]}</FeaturesList.Item>
                        )}
                        {!!surOrBedTitle && <FeaturesList.Item iconName="FloorArea">{surOrBedTitle}</FeaturesList.Item>}
                        {!!surfaceLand && <FeaturesList.Item iconName="FloorAreaLand">{surfaceLand}&nbsp;m²</FeaturesList.Item>}
                    </FeaturesList>
                </OfferCard.Features>

                <Divider color="grey-light" className="my-3" />

                <OfferCard.Price>
                    <PropertyPrice
                        truncate
                        amount={intl.formatNumber(price, {
                            style: 'currency',
                            currency,
                            maximumFractionDigits: 0,
                        })}
                        additional={
                            charges
                                ? ' + ' +
                                  intl.formatNumber(charges, {
                                      style: 'currency',
                                      currency,
                                      maximumFractionDigits: 0,
                                  })
                                : undefined
                        }
                        original={
                            discounted && originalPrice
                                ? intl.formatNumber(originalPrice, {
                                      style: 'currency',
                                      currency,
                                      maximumFractionDigits: 0,
                                  })
                                : undefined
                        }
                        discount={
                            discounted && originalPrice ? (
                                <FormattedMessage
                                    defaultMessage="Ušetříte {price}"
                                    values={{
                                        price: (
                                            <FormattedNumber
                                                value={originalPrice - price}
                                                style="currency"
                                                currency={currency}
                                                maximumFractionDigits={0}
                                            />
                                        ),
                                    }}
                                />
                            ) : undefined
                        }
                    />
                </OfferCard.Price>
            </OfferCard.Content>
        </OfferCard>
    );
};
