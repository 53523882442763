import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './OfferCardsGrid.module.scss';

type OfferCardsGridItemProps = {
	className?: string;
}

export const OfferCardsGridItem: FC<React.PropsWithChildren<OfferCardsGridItemProps>> = ({ className, children }) => {
	return <li className={classNames(styles.offerCardsGridItem, className)}>{children}</li>;
};
