import {Section} from '@components/Section';
import {Col, Row, Stack} from 'react-bootstrap';
import {Typography} from '@components/Typography';
import {OfferCardsGrid} from '@components/OfferCardsGrid';
import {AdvertOfferCard} from '@liveComponents/AdvertOfferCard';
import {AdvertImportType, Currency, Disposition, EstateType, Image, OfferType} from '.cache/__types__';
import classNames from 'classnames';
import {Button} from '@components/Button';
import {FormattedMessage} from 'react-intl';
import {Icon} from '@components/Icon';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useLocale} from '@utils/locale';

export type RelatedAdvertsType = {
    id: string;
    uri: string;
    address: string;
    surface: number;
    surfaceLand?: number;
    disposition?: Disposition;
    estateType: EstateType;
    offerType: OfferType;
    price: number;
    currency: Currency;
    charges?: number;
    imageAltText?: null | string;
    mainImage?: Image;
    isDiscounted: boolean;
    originalPrice: number;
    dataJson?: string;
    type?: AdvertImportType;
}[];

export type RelatedProps = {
    adverts: RelatedAdvertsType;
};

export const Offers = ({adverts}: RelatedProps) => {
    const locale = useLocale();
    if (adverts.length === 0) {
        return null;
    }

    const link = getLocalizedRoute(routes.search, locale, {
        discountedOnly: true,
    });
    // getLocalizedRoute(routes.search, locale, {
    //     list:
    //         advertParameters && advertParameters.offerType !== OfferType.Undefined && advertParameters.estateType !== EstateType.Undefined
    //             ? [
    //                   offerListSlugs[locale][advertParameters.offerType],
    //                   estateListSlugs[locale][advertParameters.estateType],
    //                   (
    //                       advertParameters.disposition?.filter((d) => d !== Disposition.Undefined) as
    //                           | Exclude<Disposition, Disposition.Undefined>[]
    //                           | null
    //                   )
    //                       ?.map((d) => dispositionListSlugs[locale][d])
    //                       .join(','),
    //               ]
    //                   .filter((i) => !!i)
    //                   .join('/')
    //             : '',
    // });

    return (
        <Section>
            <Row>
                <Col lg={5} xl={3}>
                    <Stack className="h-100">
                        <Typography as="h2" className="mb-5 mb-lg-8" variant="h3">
                            <FormattedMessage defaultMessage={'Máme pro vás akční nabídky'} />
                        </Typography>

                        <Typography as="p" className={classNames('mb-0 mt-auto d-none d-md-block')}>
                            <Link href={link} passHref legacyBehavior>
                                <Button variant="link">
                                    <FormattedMessage defaultMessage="Zobrazit všechny akční nabídky" />
                                    <Icon.ArrowRight position="end" />
                                </Button>
                            </Link>
                        </Typography>
                    </Stack>
                </Col>
                <Col className="mt-md-4 mt-lg-0" lg={11} xl={13}>
                    <OfferCardsGrid>
                        {adverts.map((advert) => (
                            <OfferCardsGrid.Item key={advert.id}>
                                <AdvertOfferCard
                                    key={advert.id}
                                    imageUrl={advert.mainImage?.url ?? undefined}
                                    imageAlt={advert.imageAltText ?? undefined}
                                    surface={advert.surface}
                                    surfaceLand={advert.surfaceLand}
                                    disposition={advert.disposition}
                                    estateType={advert.estateType}
                                    offerType={advert.offerType}
                                    address={advert.address}
                                    price={advert.price}
                                    currency={advert.currency}
                                    charges={advert.charges}
                                    uri={advert.uri}
                                    discounted={advert.isDiscounted}
                                    originalPrice={advert.originalPrice}
                                    dataJson={advert.dataJson}
                                    type={advert.type}
                                />
                            </OfferCardsGrid.Item>
                        ))}
                    </OfferCardsGrid>

                    <Typography as="p" className={classNames('mb-0 mt-8 d-md-none')}>
                        <Link href={link} passHref legacyBehavior>
                            <Button variant="link">
                                <FormattedMessage defaultMessage="Zobrazit všechny akční nabídky" />
                                <Icon.ArrowRight position="end" />
                            </Button>
                        </Link>
                    </Typography>
                </Col>
            </Row>
        </Section>
    );
};
