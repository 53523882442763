import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';
import styles from './FeaturesList.module.scss';

type FeaturesListItemProps = {
	iconName?: IconProps['name'];
}

export const FeaturesListItem: FC<React.PropsWithChildren<FeaturesListItemProps>> = ({ iconName, children }) => {
	return (
		<li className={styles.featuresListItem}>
			{iconName && <Icon name={iconName} className={styles.featuresListItemIcon} />}
			{children}
		</li>
	);
};
