import React, { FC } from 'react';
import { Image } from '../Image';
import styles from './OfferCard.module.scss';

export const OfferCardImage: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<figure className={styles.offerCardImage}>
			<Image layout="fill">{children}</Image>
		</figure>
	);
};
