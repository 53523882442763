import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './OfferCard.module.scss';

export const OfferCardLabel: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography
			as="span"
			variant="caption"
			textTransform="uppercase"
			fontWeight="medium"
			color="grey-dark"
			letterSpacing
			truncate
			className={classNames(styles.offerCardLabel, 'mb-1')}
		>
			{children}
		</Typography>
	);
};
