import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './FeaturesList.module.scss';
import { FeaturesListItem } from './FeaturesListItem';

type FeaturesListProps = {
	className?: string;
}

export const FeaturesList: FC<React.PropsWithChildren<FeaturesListProps>> = ({ className, children }) => {
	return <ul className={classNames(styles.featuresList, 'featuresList', className)}>{children}</ul>;
};

export default Object.assign(FeaturesList, {
	Item: FeaturesListItem,
});
