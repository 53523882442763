import {ReactNode, useEffect, useState} from 'react';
import {GetStaticProps} from 'next';
import NextImage from 'next/image';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {initializeApollo} from '@utils/apollo-client';
import {AdvertLatestDocument, AdvertLatestQuery, AdvertLatestQueryVariables} from '@gql/query/advert/latest.gql';

import {Button} from '@components/Button';
import {MainContent} from '@components/MainContent';
import {Container} from '@components/Container';
import {Row} from '@components/Row';
import {Image} from '@components/Image';
import {Icon} from '@components/Icon';
import {Col} from '@components/Col';
import {PromoCardLarge} from '@components/PromoCardLarge';
import {Section} from '@components/Section';
import {Typography} from '@components/Typography';
import {Box} from '@components/Box';
import {LargeBadge} from '@components/LargeBadge';
import {InfoBox} from '@components/InfoBox';
import {PromoBox} from '@components/PromoBox';

import {SeoHeader} from '@pageComponents/Layout/SeoHeader';
import {Intro} from '@pageComponents/HomePage/Intro';
import {Inspirations} from '@pageComponents/HomePage/Inspirations';
import {RelatedAdvertsType} from '@pageComponents/Detail/Related';
import {getGraphQlLocale} from '../types/graphql/general';
import {BlogArticle, Locale, MarketingConsentOrigin} from '.cache/__types__';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useLocale} from '@utils/locale';
import {NewsletterBanner} from '@pageComponents/MarketingConsent/NewsletterBanner';
import {LocaleEnum, verifyLocale} from 'src/types/general';
import OgImage from 'images/homepage/cover_OG_1232x660px.jpg';
import OfferTablet from 'images/nabidka_nemovitosti_tablet_376_386.jpg';
import OfferDesktop from 'images/nabidka_nemovitosti_desktop_1200_750.jpg';
import EnergieDesktop from 'images/energie_Desktop_1088x440px.jpg';
import SpareTablet from 'images/s_nami_usetrite_1_tablet.jpg';
import SpareDesktop from 'images/s_nami_usetrite_1_desktop_416x416.jpg';
import SpareTablet2 from 'images/s_nami_usetrite_2_tablet.jpg';
import SpareDesktop2 from 'images/s_nami_usetrite_2_desktop_416x416.jpg';
import {useRouter} from 'next/router';
import {SEModal} from '@liveComponents/Modal/SEModal';
import {asValue} from '@utils/query';
import {Offers} from '@pageComponents/HomePage/Offers';
import {
    BlogNewsArticleListDocument,
    BlogNewsArticleListQuery,
    BlogNewsArticleListQueryVariables,
} from '@gql/query/blog/blogNewsArticleList.gql';
import {PackageEnum, PkgType, packages} from '@hooks/useGetPackage';
import {PackageUriDocument, PackageUriQuery, PackageUriQueryVariables} from '@gql/query/package/package.gql';
import {OverlapHeroIntro} from '@components/OverlapHeroIntro';
import {CtaBlock} from '@components/CtaBlock';
import {CtaCard} from '@components/CtaCard';
import {IntroLegacy} from '@pageComponents/HomePage/IntroLegacy';
import {IntroNumbers} from '@pageComponents/HomePage/IntroNumbers';
import {IntroNumbersSk} from '@pageComponents/HomePage/IntroNumbersSk';

export const getStaticProps: GetStaticProps = async ({locale}) => {
    const client = initializeApollo();
    const gqlLocale = getGraphQlLocale(locale);

    const listAdverts =
        locale !== Locale.Cs
            ? await client
                  .query<AdvertLatestQuery, AdvertLatestQueryVariables>({
                      query: AdvertLatestDocument,
                      variables: {
                          locale: gqlLocale === Locale.En ? Locale.Cs : gqlLocale,
                      },
                  })
                  .then((r) => {
                      console.log(r);
                      return r;
                  })
                  .then((res) => [
                      ...(res.data.lastDiscounted?.list?.slice(0, 6) ?? []),
                      ...(res.data.listAdverts?.list?.slice(
                          0,
                          typeof res.data.lastDiscounted?.totalCount === 'number' && res.data.lastDiscounted.totalCount < 6
                              ? 6 - (res.data.lastDiscounted.list?.length ?? 0)
                              : 0,
                      ) ?? []),
                  ])
            : [];

    const blogArticles = await client
        .query<BlogNewsArticleListQuery, BlogNewsArticleListQueryVariables>({
            query: BlogNewsArticleListDocument,
            variables: {
                locale: gqlLocale,
            },
        })
        .then((res) => res.data.blogNewsArticleList?.list ?? []);

    const pkg = await client
        .query<PackageUriQuery, PackageUriQueryVariables>({
            query: PackageUriDocument,
            variables: {uri: packages[PackageEnum.LegalService][verifyLocale(locale)], locale: gqlLocale},
        })
        .then((res) => res.data.package);

    return {
        props: {
            relatedAdverts: listAdverts,
            blogArticles,
            dlObject: {
                pageType: 'homepage',
            },
            staticRandom: Math.random(),
            pkg,
        },
        revalidate: 600,
    };
};

export type HomePageProps = {
    relatedAdverts: RelatedAdvertsType;
    blogArticles: BlogArticle[];
    staticRandom: number;
} & PkgType;

const HomePage = ({relatedAdverts, blogArticles, pkg /*staticRandom*/}: HomePageProps) => {
    const intl = useIntl();
    const locale = useLocale();
    const {query} = useRouter();
    const [newsletterModalOpen, setNewsletterModalOpen] = useState<boolean>(false);

    const showMortAndEnergy = locale === LocaleEnum.cs;

    useEffect(() => {
        if (query.action && query.action === 'gdpr_popup') {
            setNewsletterModalOpen(true);
        }
    }, [query]);

    /*const hiringBox = useMemo(() => {
        return (
            <Col xs={11} md={8} lg={6}>
                <PromoCardLarge variant="white">
                    <PromoCardLarge.Content>
                        <PromoCardLarge.Heading>
                            Přidej se k nám!
                        </PromoCardLarge.Heading>

                        <PromoCardLarge.Desc>Zákaznický specialista na prodeje</PromoCardLarge.Desc>
                    </PromoCardLarge.Content>

                    <a href="https://www.startupjobs.cz/admin/bezrealitky-s-r-o/nabidka/43511/zakaznicky-specialista-na-prodeje-pro-bezrealitky">
                        <PromoCardLarge.Button>Chci vědět víc</PromoCardLarge.Button>
                    </a>
                </PromoCardLarge>
            </Col>
        );
    }, []);*/

    return (
        <>
            <SeoHeader
                title={intl.formatMessage({defaultMessage: 'Bezrealitky - prodej a pronájem nemovitostí bez provize'})}
                description={intl.formatMessage({
                    defaultMessage:
                        'Prodejte svou nemovitost přímo zájemcům - bez realitky a bez provize. Přímý prodej a pronájem nemovitostí - prodej bytů, domů, pozemků.',
                })}
                image={OgImage.src}
                hrefLangRoute={{route: routes.homepage}}
                canonical={{route: routes.homepage}}
            />

            <MainContent>
                <Section className="mb-10" centerDrawnBackground>
                    <Container variant="wide">{locale === LocaleEnum.en ? <IntroLegacy locale={locale} /> : <Intro />}</Container>
                </Section>

                <Container>
                    {locale === LocaleEnum.en ? (
                        <Offers adverts={relatedAdverts} />
                    ) : (
                        <>
                            <Section className="mb-10 mb-md-15 mb-xl-16">
                                <OverlapHeroIntro>
                                    <Typography
                                        as="h2"
                                        variant="h4"
                                        textAlign="center"
                                        fontWeight="bold"
                                        color="white"
                                        className="mb-4 mb-md-6"
                                    >
                                        <FormattedMessage defaultMessage="Služby pro majitele nemovitostí" />
                                    </Typography>

                                    <CtaBlock variant="simple">
                                        <CtaCard>
                                            <CtaCard.Heading variant="h4" textAlign="center" fontWeight="bold" hasBorder={false}>
                                                <FormattedMessage defaultMessage="Chci pronajmout" />
                                            </CtaCard.Heading>

                                            <CtaCard.Button>
                                                <Link href={getLocalizedRoute(routes.howToRent, locale)}>
                                                    <Button variant="secondary" size="md">
                                                        <FormattedMessage defaultMessage="Zjistit více" />
                                                    </Button>
                                                </Link>
                                            </CtaCard.Button>
                                        </CtaCard>

                                        <CtaCard>
                                            <CtaCard.Heading variant="h4" textAlign="center" fontWeight="bold" hasBorder={false}>
                                                <FormattedMessage defaultMessage="Chci prodat" />
                                            </CtaCard.Heading>

                                            <CtaCard.Button>
                                                <Link href={getLocalizedRoute(routes.howToSell, locale)}>
                                                    <Button variant="secondary" size="md">
                                                        <FormattedMessage defaultMessage="Zjistit více" />
                                                    </Button>
                                                </Link>
                                            </CtaCard.Button>
                                        </CtaCard>
                                    </CtaBlock>
                                </OverlapHeroIntro>
                            </Section>

                            <Section className="mb-xl-16">{locale === LocaleEnum.sk ? <IntroNumbersSk /> : <IntroNumbers />}</Section>
                        </>
                    )}

                    <Section>
                        {/* Commented as hiring is disabled}
                    <Box className="overflow-hidden h-100 d-lg-none mb-4" rounded="sm">
                        <Image>
                            <NextImage src={ImageHiringTablet} width={978} height={550} placeholder="blur" />
                        </Image>
                    </Box>
                    {*/}

                        <Box as={Row} className="g-4 g-xl-8 flex-nowrap flex-md-wrap pb-4 pb-md-0" scrollable="horizontal">
                            <Col xs={showMortAndEnergy ? 11 : 16} md={16} lg={showMortAndEnergy ? 10 : 16}>
                                <PromoCardLarge variant="green" mainHeading={intl.formatMessage({defaultMessage: 'Věděli jste, že…'})}>
                                    <PromoCardLarge.Content>
                                        <PromoCardLarge.Heading>
                                            <FormattedMessage
                                                defaultMessage={
                                                    'Byt na pronájem si v průměru najde 1.\u00a0zájemce za <strong>23\u00a0minut</strong>.'
                                                }
                                                values={{
                                                    strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
                                                }}
                                            />
                                        </PromoCardLarge.Heading>

                                        <PromoCardLarge.Desc>
                                            <FormattedMessage defaultMessage="Buďte ten první vy." />
                                        </PromoCardLarge.Desc>
                                    </PromoCardLarge.Content>

                                    <Link href={getLocalizedRoute(routes.serviceCentrePremium, locale)} passHref legacyBehavior>
                                        <PromoCardLarge.Button>
                                            <FormattedMessage defaultMessage="Chci Premium profil" />
                                        </PromoCardLarge.Button>
                                    </Link>
                                </PromoCardLarge>
                            </Col>

                            {showMortAndEnergy && (
                                <>
                                    <Col xs={11} md={8} lg={6}>
                                        <PromoCardLarge variant="white">
                                            <PromoCardLarge.Content>
                                                {pkg.price && pkg.currency && (
                                                    <PromoCardLarge.Heading>
                                                        Prodáváte nemovitost? Právní dokumenty vyřídíme za vás za{' '}
                                                        <FormattedNumber
                                                            value={pkg.price}
                                                            style="currency"
                                                            maximumFractionDigits={0}
                                                            currency={pkg.currency}
                                                        />
                                                        .
                                                    </PromoCardLarge.Heading>
                                                )}

                                                <PromoCardLarge.Desc>S&nbsp;prověřeným advokátem a do&nbsp;5&nbsp;dnů.</PromoCardLarge.Desc>
                                            </PromoCardLarge.Content>

                                            <Link
                                                href={getLocalizedRoute(routes.serviceCentreLegalService, locale)}
                                                passHref
                                                legacyBehavior
                                            >
                                                <PromoCardLarge.Button>Potřebuji advokátní služby</PromoCardLarge.Button>
                                            </Link>
                                        </PromoCardLarge>
                                    </Col>

                                    <Col lg={{order: 0, span: 10}} className="d-none d-lg-block">
                                        <Box className="overflow-hidden h-100 position-relative" rounded="lg">
                                            <Image layout="fill">
                                                <NextImage
                                                    alt={'Převod energií'}
                                                    src={EnergieDesktop}
                                                    layout="fill"
                                                    sizes={'62.5vw'}
                                                    objectFit="cover"
                                                    placeholder="blur"
                                                />
                                            </Image>
                                        </Box>
                                    </Col>

                                    <Col xs={11} md={8} lg={6}>
                                        <PromoCardLarge variant="white">
                                            <PromoCardLarge.Content>
                                                <PromoCardLarge.Heading>
                                                    <FormattedMessage
                                                        defaultMessage="Nejméně oblíbenou činností při stěhování je <strong>přepis energií</strong>."
                                                        values={{
                                                            strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
                                                        }}
                                                    />
                                                </PromoCardLarge.Heading>

                                                <PromoCardLarge.Desc>
                                                    <FormattedMessage defaultMessage="S námi to tak nebolí." />
                                                </PromoCardLarge.Desc>
                                            </PromoCardLarge.Content>

                                            <Link href={getLocalizedRoute(routes.serviceCentreEnergy, locale)} passHref>
                                                <PromoCardLarge.Button>
                                                    <FormattedMessage defaultMessage="Budu potřebovat převést energie" />
                                                </PromoCardLarge.Button>
                                            </Link>
                                        </PromoCardLarge>
                                    </Col>

                                    {/*}
                                <Col lg={{order: 0, span: 10}} className="d-none d-lg-block">
                                    <Box className="overflow-hidden h-100 position-relative" rounded="lg">
                                        <Image layout="fill">
                                            <NextImage
                                                alt={intl.formatMessage({defaultMessage: "Nabíráne"})}
                                                src={ImageHiring}
                                                placeholder="blur"
                                                fill
                                                sizes="100vw"
                                                style={{
                                                    objectFit: "cover"
                                                }} />
                                        </Image>
                                    </Box>
                                </Col>

                                {hiringBox}
                                {*/}
                                </>
                            )}
                        </Box>
                    </Section>

                    <Section>
                        <Typography as="h2" variant="h1" className="mb-6 mb-lg-14">
                            <FormattedMessage defaultMessage="S námi ušetříte {br} zásadně" values={{br: <br />}} />
                        </Typography>

                        <Box className="d-none d-xl-block translate-middle position-absolute zindex-1 start-50">
                            <LargeBadge>
                                <FormattedMessage defaultMessage="U nás provize nehledejte" />
                            </LargeBadge>
                        </Box>

                        <Box className="overflow-hidden h-100 d-md-none mb-4" rounded="sm">
                            <Image>
                                <NextImage
                                    alt={intl.formatMessage({defaultMessage: 'Úspora'})}
                                    src={SpareTablet}
                                    width={978}
                                    height={550}
                                    placeholder="blur"
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </Image>
                        </Box>

                        <Box as={Row} className="g-4 g-xl-8 flex-nowrap flex-md-wrap pb-4 pb-md-0" scrollable="horizontal">
                            <Col md={8} xxl={4} className="d-none d-md-block pt-xxl-15">
                                <Box className="overflow-hidden position-relative h-100 d-none d-xxl-block" rounded="lg">
                                    <Image layout="fill">
                                        <NextImage
                                            alt={intl.formatMessage({defaultMessage: 'Úspora'})}
                                            src={SpareDesktop}
                                            placeholder="blur"
                                            fill
                                            sizes="100vw"
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Image>
                                </Box>

                                <Box className="overflow-hidden position-relative h-100 d-xxl-none mb-4" rounded="sm">
                                    <Image layout="fill">
                                        <NextImage
                                            alt={intl.formatMessage({defaultMessage: 'Úspora'})}
                                            src={SpareTablet}
                                            placeholder="blur"
                                            fill
                                            sizes="100vw"
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Image>
                                </Box>
                            </Col>

                            <Col xs={11} md={{order: 'first', span: 8}} xxl={{order: 0, span: 4}} className="pb-xxl-15">
                                <InfoBox>
                                    <InfoBox.Heading>
                                        <FormattedMessage defaultMessage="Když chcete prodat..." />
                                    </InfoBox.Heading>
                                    <InfoBox.Text>
                                        <FormattedMessage defaultMessage="Průměrná provize realitní kanceláři činí 4 % z ceny nemovitosti. U nemovitosti za 6\u00a0000\u00a0000 Kč byste tak zaplatili 240\u00a0000 Kč za provizi." />
                                    </InfoBox.Text>
                                </InfoBox>
                            </Col>

                            <Col md={8} xxl={4} className="d-none d-md-block pt-xxl-15">
                                <Box className="overflow-hidden position-relative h-100 d-none d-xxl-block" rounded="lg">
                                    <Image layout="fill">
                                        <NextImage
                                            alt={intl.formatMessage({defaultMessage: 'Úspora'})}
                                            src={SpareDesktop2}
                                            placeholder="blur"
                                            fill
                                            sizes="100vw"
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Image>
                                </Box>

                                <Box className="overflow-hidden position-relative h-100 d-xxl-none mb-4" rounded="sm">
                                    <Image layout="fill">
                                        <NextImage
                                            alt={intl.formatMessage({defaultMessage: 'Úspora'})}
                                            src={SpareTablet2}
                                            placeholder="blur"
                                            fill
                                            sizes="100vw"
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Image>
                                </Box>
                            </Col>

                            <Col xs={11} md={8} xxl={4} className="pb-xxl-15">
                                <InfoBox variant="grey">
                                    <InfoBox.Heading>
                                        <FormattedMessage defaultMessage="Když chcete pronájem bez provize..." />
                                    </InfoBox.Heading>
                                    <InfoBox.Text>
                                        <FormattedMessage defaultMessage="S realitní kanceláří platíte provizi i v případě nájmu. Obvyklá provize je rovna jednomu mesíčnímu nájmu, často 15\u00a0-\u00a020\u00a0000\u00a0Kč." />
                                    </InfoBox.Text>
                                </InfoBox>
                            </Col>
                        </Box>
                    </Section>

                    <Section>
                        <PromoBox>
                            <PromoBox.Content>
                                <PromoBox.Heading>
                                    <FormattedMessage defaultMessage="Nabídněte svou nemovitost {br} ostatním" values={{br: <br />}} />
                                </PromoBox.Heading>

                                <Typography as="p" variant="perex" className="mb-7 mb-md-10">
                                    <FormattedMessage defaultMessage="Ať už chcete prodat nebo pronajmout, s námi máte na výběr. Můžete to zkusit sami nebo vám s tím pomůžeme." />
                                </Typography>

                                <Box className="mt-auto align-self-md-start">
                                    <Link href={getLocalizedRoute(routes.serviceCentreIntroOwner, locale)} passHref legacyBehavior>
                                        <Button variant="secondary" className="w-100" sideToSide>
                                            <Typography variant="cta">
                                                <FormattedMessage defaultMessage="Nabídnout nemovitost" />
                                            </Typography>
                                            <Icon.Key position="end" />
                                        </Button>
                                    </Link>
                                </Box>
                            </PromoBox.Content>

                            <PromoBox.Image>
                                <NextImage
                                    alt={intl.formatMessage({defaultMessage: 'Nabídka'})}
                                    className={'d-md-none'}
                                    src={OfferTablet}
                                    placeholder="blur"
                                    fill
                                    sizes="100vw"
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                                <NextImage
                                    alt={intl.formatMessage({defaultMessage: 'Nabídka'})}
                                    className={'d-none d-md-block'}
                                    src={OfferDesktop}
                                    placeholder="blur"
                                    fill
                                    sizes="100vw"
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            </PromoBox.Image>
                        </PromoBox>
                    </Section>
                </Container>

                <Section>
                    <Inspirations blogArticles={blogArticles} />
                </Section>

                <Container>
                    <NewsletterBanner origin={MarketingConsentOrigin.Hp} />
                </Container>
            </MainContent>

            <SEModal
                ulid={asValue(query.hash)}
                show={newsletterModalOpen}
                onHide={() => {
                    setNewsletterModalOpen(false);
                }}
            />
        </>
    );
};

export default HomePage;
