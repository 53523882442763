import {AdvertImportType, Education, EstateType} from '.cache/__types__';
import {User} from '@utils/user';
import {IntlShape} from 'react-intl';
import {StringObj} from 'src/types/general';
import {DateTime} from 'luxon';

export const getSurface = (estateType: EstateType, surface: number, surfaceLand: number) => {
    if (estateType === EstateType.Pozemek && surfaceLand > 0) {
        return surfaceLand;
    }

    return surface;
};

type DataJsonType = {
    loznice: string;
} | null;

export const getBedrooms = (type?: AdvertImportType, dataJson?: string) => {
    if (!dataJson || !type) {
        return null;
    }

    const result: DataJsonType = type === AdvertImportType.EChalupy && dataJson ? JSON.parse(dataJson) : null;

    return result?.loznice ?? null;
};

export const getSurfaceOrBedroomTitle = (surface: number, bedrooms: string | null, decorate = false) => {
    const bullet = decorate ? '• ' : '';
    const surfaceTitle = surface > 0 ? `${bullet}${surface}\u00a0m²` : null;
    const bedroomsTitle = !surfaceTitle && bedrooms ? `${bullet}${bedrooms.replace(' ', '\u00a0')}` : '';

    return surfaceTitle ?? bedroomsTitle;
};

export const getIsRk = (type: AdvertImportType) =>
    [
        AdvertImportType.Bures,
        AdvertImportType.Maxima,
        AdvertImportType.Comfort,
        AdvertImportType.DokonalyNajemnik,
        AdvertImportType.TvujSpravce,
        AdvertImportType.Mm,
        AdvertImportType.ManualBzrMajitelProdej,
        AdvertImportType.ManualBzrMajitelPronajem,
        AdvertImportType.ManualBzrRkProdej,
        AdvertImportType.ManualBzrRkPronajem,
        AdvertImportType.ManualBzrSpravceProdej,
        AdvertImportType.ManualBzrSpravcePronajem,
    ].includes(type);

export const getContactOwnerTitle = (intl: IntlShape, type?: AdvertImportType) => {
    switch (type) {
        case AdvertImportType.Mm:
        case AdvertImportType.Maxima:
        case AdvertImportType.Comfort:
        case AdvertImportType.ManualBzrRkProdej:
        case AdvertImportType.ManualBzrRkPronajem:
            return intl.formatMessage({defaultMessage: 'Inzerát realitní společnosti'});

        case AdvertImportType.Bures:
        case AdvertImportType.TvujSpravce:
        case AdvertImportType.DokonalyNajemnik:
        case AdvertImportType.ManualBzrSpravceProdej:
        case AdvertImportType.ManualBzrSpravcePronajem:
            return intl.formatMessage({defaultMessage: 'Inzerát správcovské společnosti'});

        case AdvertImportType.Beyvak:
        case AdvertImportType.Heimstaden:
        case AdvertImportType.ManualBzrMajitelProdej:
        case AdvertImportType.ManualBzrMajitelPronajem:
            return intl.formatMessage({defaultMessage: 'Inzerát přímého majitele'});

        default:
            return intl.formatMessage({defaultMessage: 'Reagovat na inzerát'});
    }
};

export const createUserParams = (user: User | null) => {
    const userParams: StringObj = {};
    if (user?.firstname) {
        userParams.jmeno = user.firstname;
    }
    if (user?.lastname) {
        userParams.prijmeni = user.lastname;
    }

    if (user?.email) {
        userParams.email = user.email;
    }

    if (user?.phone) {
        userParams.telefon = user.phone;
    }

    if (user?.id) {
        userParams.id = user.id;
    }
    return userParams;
};

const OWNEST_LOCAL_STORAGE_KEY = 'ownest';
export const getOwnestLocalStorage = () => typeof localStorage !== 'undefined' && localStorage.getItem(OWNEST_LOCAL_STORAGE_KEY) === 'true';
export const setOwnestLocalStorage = (value: boolean) => {
    typeof localStorage !== 'undefined' && localStorage.setItem(OWNEST_LOCAL_STORAGE_KEY, value ? 'true' : 'false');
};

export const hasCompleteProfile = (user: any) =>
    !!user?.yearBorn && !!user.familyMembers && !!user.workplace && !!user.education && user.education !== Education.Undefined;

export const hasMedailon = (user: any) => user.noteIsFilled;

export const showGallery = (advert: {active?: boolean | null; timeDeactivated?: number | null}) => {
    if (advert.active) {
        return true;
    }

    const timeDeactivated = advert.timeDeactivated ? DateTime.fromSeconds(advert.timeDeactivated) : null;
    if (timeDeactivated && Math.abs(timeDeactivated.diffNow('days').days) <= 14) {
        return true;
    }

    return false;
};
