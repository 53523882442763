import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './OfferCard.module.scss';

export const OfferCardAddress: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography as="span" variant="perex" truncate className={styles.offerCardAddress}>
			{children}
		</Typography>
	);
};
