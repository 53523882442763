import classNames from 'classnames';
import React, {FC} from 'react';
import {Typography} from '../Typography';
import styles from './PropertyPrice.module.scss';

export type PropertyPriceProps = {
    amount: string | React.ReactElement;
    original?: string | React.ReactElement;
    discount?: string | React.ReactElement;
    additional?: string;
    pricePerMeter?: string | React.ReactElement;
    className?: string;
    truncate?: boolean;
    compact?: boolean;
};

export const PropertyPrice: FC<React.PropsWithChildren<PropertyPriceProps>> = ({
    amount,
    additional,
    original,
    discount,
    pricePerMeter,
    className,
    truncate,
    compact,
}) => {
    return (
        <Typography
            as="div"
            className={classNames(styles.propertyPrice, 'propertyPrice mb-0', className, {
                [styles['propertyPrice--compact']]: compact,
            })}
        >
            <span
                className={classNames(styles.propertyPriceAmount, {
                    'text-truncate': truncate,
                })}
            >
                {amount}
            </span>
            {additional && <span className={styles.propertyPriceAdditional}>{additional}</span>}
            {!!pricePerMeter && <span className={styles.propertyPricePerMeter}>({pricePerMeter})</span>}
            <span className={'w-100'}></span>
            {original && (
                <>
                    <span className={styles.propertyPriceOriginal}>
                        {original}
                        <hr className={styles['propertyPriceOriginal--crossLine']} />
                    </span>
                    <span className={classNames(styles.propertyPriceDiscount)}>{discount}</span>
                </>
            )}
        </Typography>
    );
};
