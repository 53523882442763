import React, {useMemo, useReducer} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Link from 'next/link';

import {entries, LocaleEnum} from 'src/types/general';
import {translations as estateTranslations} from '@constants/EstateType';
import {offerEstateTypeImperativeTranslation, translations as offerTranslations} from '@constants/OfferType';
import {getInitialState, initialState, onSelectFeature, reducer, setCurrencyFilter, setIntFilter, setRadioFilter} from './reducer';
import {ActionTypes, IntFilterEnum, RadioFilterEnum, Toggles} from './types';

import {Col} from '@components/Col';
import {ContentBox} from '@components/ContentBox';
import {Form} from '@components/Form';
import {Row} from '@components/Row';
import {SearchPanel} from '@components/SearchPanel';
import {Autocomplete} from '@liveComponents/Autocomplete/Autocomplete';

import {Currency, Disposition, EstateType, OfferType} from '.cache/__types__';
import {routes} from '@utils/routes';
import {getSearchURL, loadFilter, saveFilter} from '@utils/query';
import {useLocale} from '@utils/locale';
import {formatMoneyValue} from './Range';
import {SearchPanelMobile} from './SearchPanelMobile';
import {InputGroup} from '@components/InputGroup';
import {Tag} from '@components/Tag';
import {Typography} from '@components/Typography';
import {getImperativeTranslation} from '@constants/Disposition';
import {defaultCurrencyByLocale} from '@constants/Currency';
import {dataLayerPush} from '@utils/gtm';
import {label as offerLabel} from '@constants/OfferType';
import {label as estateLabel} from '@constants/EstateType';

const ESTATE_TYPES = entries(estateTranslations);
const OFFER_TYPES = entries(offerTranslations);

export const priceToData = {
    [OfferType.Prodej]: [
        {
            values: {[LocaleEnum.cs]: '2000000', [LocaleEnum.en]: '2000000', [LocaleEnum.sk]: '80000'},
            title: {[LocaleEnum.cs]: '2', [LocaleEnum.en]: '2', [LocaleEnum.sk]: '80'},
        },
        {
            values: {[LocaleEnum.cs]: '5000000', [LocaleEnum.en]: '5000000', [LocaleEnum.sk]: '200000'},
            title: {[LocaleEnum.cs]: '5', [LocaleEnum.en]: '5', [LocaleEnum.sk]: '200'},
        },
        {
            values: {[LocaleEnum.cs]: '10000000', [LocaleEnum.en]: '10000000', [LocaleEnum.sk]: '400000'},
            title: {[LocaleEnum.cs]: '10', [LocaleEnum.en]: '10', [LocaleEnum.sk]: '400'},
        },
    ],
    [OfferType.Pronajem]: [
        {
            values: {[LocaleEnum.cs]: '10000', [LocaleEnum.en]: '10000', [LocaleEnum.sk]: '400'},
            title: {[LocaleEnum.cs]: '10', [LocaleEnum.en]: '10', [LocaleEnum.sk]: '400'},
        },
        {
            values: {[LocaleEnum.cs]: '15000', [LocaleEnum.en]: '15000', [LocaleEnum.sk]: '600'},
            title: {[LocaleEnum.cs]: '15', [LocaleEnum.en]: '15', [LocaleEnum.sk]: '600'},
        },
        {
            values: {[LocaleEnum.cs]: '20000', [LocaleEnum.en]: '20000', [LocaleEnum.sk]: '800'},
            title: {[LocaleEnum.cs]: '20', [LocaleEnum.en]: '20', [LocaleEnum.sk]: '800'},
        },
    ],
};

export const Search = () => {
    const locale = useLocale();
    const intl = useIntl();
    const lastFilter = loadFilter();
    const [state, dispatch] = useReducer(reducer, initialState, getInitialState(lastFilter, locale));

    const offerType = state.filter.offerType[0] as OfferType.Prodej | OfferType.Pronajem;
    const estateType = state.filter.estateType[0];

    const estateDropdownContent = useMemo(
        () => (
            <ContentBox className="p-4 py-md-10 px-md-12">
                {ESTATE_TYPES.map((row) => (
                    <Form.Check
                        key={row[0]}
                        id={row[0]}
                        type="radio"
                        label={row[1]}
                        name="property-estate-type"
                        className="mb-3 mb-md-5"
                        controlOnRight
                        onChange={setRadioFilter(dispatch)({attr: RadioFilterEnum.Estate, value: row[0]})}
                        checked={row[0] === estateType}
                    />
                ))}
            </ContentBox>
        ),
        [estateType],
    );

    const offerDropdownContent = useMemo(
        () => (
            <ContentBox className="p-4 py-md-10 px-md-12">
                {OFFER_TYPES.filter(([key]) => [OfferType.Prodej, OfferType.Pronajem].includes(key)).map((row) => {
                    return (
                        <Form.Check
                            key={row[0]}
                            id={row[0]}
                            type="radio"
                            label={row[1]}
                            name="property-offer-type"
                            className="mb-3 mb-md-5"
                            controlOnRight
                            onChange={setRadioFilter(dispatch)({attr: RadioFilterEnum.Offer, value: row[0]})}
                            checked={row[0] === offerType}
                        />
                    );
                })}
            </ContentBox>
        ),
        [offerType],
    );

    const priceDropdownContent = useMemo(
        () => (
            <ContentBox className="p-4 py-md-6 px-md-6">
                <InputGroup>
                    <Form.Control
                        placeholder={intl.formatMessage({defaultMessage: 'Cena od'})}
                        value={state.filter.priceFrom ?? ''}
                        onChange={setIntFilter(dispatch)(IntFilterEnum.PriceFrom)}
                    />
                    <InputGroup.Text>
                        <Form.Select name="currency" value={state.filter.currency!} className="ms-0" onChange={setCurrencyFilter(dispatch)}>
                            {[
                                {
                                    name: 'Kč',
                                    value: Currency.Czk,
                                },
                                {
                                    name: 'EUR',
                                    value: Currency.Eur,
                                },
                            ].map(({name, value}) => (
                                <option key={value} value={value}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mt-3">
                    <Form.Control
                        placeholder={intl.formatMessage({defaultMessage: 'Cena do'})}
                        value={state.filter.priceTo ?? ''}
                        onChange={setIntFilter(dispatch)(IntFilterEnum.PriceTo)}
                    />
                    <InputGroup.Text>
                        <Form.Select name="currency" value={state.filter.currency!} className="ms-0" onChange={setCurrencyFilter(dispatch)}>
                            {[
                                {
                                    name: 'Kč',
                                    value: Currency.Czk,
                                },
                                {
                                    name: 'EUR',
                                    value: Currency.Eur,
                                },
                            ].map(({name, value}) => (
                                <option key={value} value={value}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup.Text>
                </InputGroup>

                {priceToData[offerType].map((row) => (
                    <Tag key={row.title[locale]} size="xs" variant="dark" className="mt-3 me-2 cursor-pointer">
                        <a
                            onClick={() => {
                                setIntFilter(dispatch)(IntFilterEnum.PriceTo)({
                                    target: {value: row.values[locale]},
                                } as React.ChangeEvent<HTMLInputElement>);
                            }}
                        >
                            {offerType === OfferType.Prodej ? (
                                <FormattedMessage defaultMessage="Do {priceTo} mil" values={{priceTo: row.title[locale]}} />
                            ) : (
                                <FormattedMessage defaultMessage="Do {priceTo} tis" values={{priceTo: row.title[locale]}} />
                            )}
                        </a>
                    </Tag>
                ))}
            </ContentBox>
        ),
        [intl, state.filter.priceFrom, state.filter.currency, state.filter.priceTo, offerType, locale],
    );

    const regions = lastFilter.osm_value?.split(',').at(0) ? `, ${lastFilter.osm_value.split(',').at(0)}` : '';
    const dispositions = lastFilter.disposition
        ? `, ${lastFilter.disposition.map((d: Disposition) => getImperativeTranslation(intl, d)).join(', ')}`
        : '';
    const priceFrom = lastFilter.priceFrom
        ? ` ${intl.formatMessage({defaultMessage: 'od'})} ${intl.formatNumber(lastFilter.priceFrom, {
              style: 'currency',
              currency: lastFilter.currency ?? defaultCurrencyByLocale[locale],
              maximumFractionDigits: 0,
          })}`
        : '';
    const priceTo = lastFilter.priceTo
        ? ` ${intl.formatMessage({defaultMessage: 'do'})} ${intl.formatNumber(lastFilter.priceTo, {
              style: 'currency',
              currency: lastFilter.currency ?? defaultCurrencyByLocale[locale],
              maximumFractionDigits: 0,
          })}`
        : '';

    const priceLocale = state.filter.currency === Currency.Czk ? LocaleEnum.cs : LocaleEnum.sk;

    return (
        <>
            <SearchPanel>
                <Row className="flex-nowrap gx-2 gx-md-3">
                    <Col>
                        <SearchPanel.InputsGroup>
                            <SearchPanel.InputsItem className="d-none d-md-block">
                                <SearchPanel.InputButton
                                    label={offerLabel}
                                    onClick={() => {
                                        dispatch({type: ActionTypes.toggle, attr: Toggles.OfferType});
                                    }}
                                    active={state.showOfferTypeDropdown}
                                >
                                    {offerTranslations[state.filter.offerType[0]]}
                                </SearchPanel.InputButton>

                                <SearchPanel.InputDropdown
                                    position="left"
                                    visible={state.showOfferTypeDropdown}
                                    onClickOutside={() => {
                                        dispatch({
                                            type: ActionTypes.setToggle,
                                            attr: Toggles.OfferType,
                                            value: false,
                                        });
                                    }}
                                >
                                    {offerDropdownContent}
                                </SearchPanel.InputDropdown>
                            </SearchPanel.InputsItem>

                            <SearchPanel.InputsItem className="d-none d-md-block">
                                <SearchPanel.InputButton
                                    label={estateLabel}
                                    onClick={() => {
                                        dispatch({type: ActionTypes.toggle, attr: Toggles.EstateType});
                                    }}
                                    active={state.showEstateTypeDropdown}
                                >
                                    {state.filter.estateType[0] === EstateType.Undefined ? (
                                        <FormattedMessage defaultMessage="Byt, dům, pozemek…" />
                                    ) : (
                                        estateTranslations[state.filter.estateType[0]]
                                    )}
                                </SearchPanel.InputButton>

                                <SearchPanel.InputDropdown
                                    position="left"
                                    visible={state.showEstateTypeDropdown}
                                    onClickOutside={() => {
                                        dispatch({
                                            type: ActionTypes.setToggle,
                                            attr: Toggles.EstateType,
                                            value: false,
                                        });
                                    }}
                                >
                                    {estateDropdownContent}
                                </SearchPanel.InputDropdown>
                            </SearchPanel.InputsItem>

                            <SearchPanel.InputsItem>
                                <SearchPanel.InputButton
                                    className="d-md-none"
                                    active={state.showSearchSidebar}
                                    onClick={() => {
                                        dispatch({
                                            type: ActionTypes.setToggle,
                                            attr: Toggles.SearchSidebar,
                                            value: true,
                                        });
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Začněte hledat" />
                                </SearchPanel.InputButton>
                                <Autocomplete
                                    countryCode={locale === LocaleEnum.sk ? 'sk' : 'cz'}
                                    onSelect={onSelectFeature(dispatch)}
                                    value={state.filter.osm_value}
                                    renderInputComponent={(
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        {size, ...inputProps},
                                    ) => (
                                        <SearchPanel.Input
                                            {...inputProps}
                                            key={'locality'}
                                            className="d-none d-md-block"
                                            label={intl.formatMessage({defaultMessage: 'Lokalita'})}
                                            placeholder={intl.formatMessage({defaultMessage: 'Kraj, okres nebo adresa…'})}
                                        />
                                    )}
                                    homePage
                                    currentLocation={{
                                        position: 'suggestionBox',
                                        setCurrentLocationUsed: (value) => {
                                            dispatch({
                                                type: ActionTypes.setIntFilter,
                                                attr: IntFilterEnum.PolygonBuffer,
                                                value: value ? 5000 : initialState.filter.polygonBuffer,
                                            });
                                        },
                                    }}
                                />
                            </SearchPanel.InputsItem>

                            <SearchPanel.InputsItem className="d-none d-md-block">
                                <SearchPanel.InputButton
                                    label={intl.formatMessage({defaultMessage: 'Cena'})}
                                    onClick={() => {
                                        dispatch({type: ActionTypes.toggle, attr: Toggles.Price});
                                    }}
                                    active={state.showPriceDropdown}
                                >
                                    {!!state.filter.priceFrom &&
                                        !!state.filter.priceTo &&
                                        `${formatMoneyValue(priceLocale, state.filter.priceFrom)} - ${formatMoneyValue(
                                            priceLocale,
                                            state.filter.priceTo,
                                        )}`}

                                    {!!state.filter.priceFrom &&
                                        !state.filter.priceTo &&
                                        intl.formatMessage(
                                            {defaultMessage: 'od {priceFrom}'},
                                            {priceFrom: formatMoneyValue(priceLocale, state.filter.priceFrom)},
                                        )}

                                    {!state.filter.priceFrom &&
                                        !!state.filter.priceTo &&
                                        intl.formatMessage(
                                            {defaultMessage: 'do {priceTo}'},
                                            {priceTo: formatMoneyValue(priceLocale, state.filter.priceTo)},
                                        )}

                                    {!state.filter.priceFrom && !state.filter.priceTo && <FormattedMessage defaultMessage="Nezáleží" />}
                                </SearchPanel.InputButton>

                                <SearchPanel.InputDropdown
                                    position="right"
                                    visible={state.showPriceDropdown}
                                    onClickOutside={() => {
                                        dispatch({
                                            type: ActionTypes.setToggle,
                                            attr: Toggles.Price,
                                            value: false,
                                        });
                                    }}
                                >
                                    {priceDropdownContent}
                                </SearchPanel.InputDropdown>
                            </SearchPanel.InputsItem>
                        </SearchPanel.InputsGroup>
                    </Col>

                    <Col xs="auto">
                        <SearchPanel.Button
                            className="d-md-none"
                            onClick={() => {
                                dispatch({
                                    type: ActionTypes.setToggle,
                                    attr: Toggles.SearchSidebar,
                                    value: true,
                                });
                            }}
                        >
                            <FormattedMessage defaultMessage="Hledat" />
                        </SearchPanel.Button>
                        <Link
                            href={getSearchURL(routes.search, locale)(state.filter)}
                            onClick={() => {
                                saveFilter(state.filter);
                                dataLayerPush({
                                    event: 'HP_hledani',
                                });
                            }}
                        >
                            <SearchPanel.Button className="d-none d-md-block">
                                <FormattedMessage defaultMessage="Hledat" />
                            </SearchPanel.Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    {lastFilter.estateType || lastFilter.offerType ? (
                        <Typography variant="caption" color="grey-dark" className="ps-6 pt-2">
                            <FormattedMessage defaultMessage="Naposledy hledáno:" />{' '}
                            <Link href={getSearchURL(routes.search, locale)(lastFilter)}>
                                {`${offerEstateTypeImperativeTranslation(
                                    intl,
                                    lastFilter.offerType?.[0],
                                    lastFilter.estateType?.[0],
                                )}${regions}${dispositions}${priceFrom}${priceTo}`}
                            </Link>
                        </Typography>
                    ) : null}
                </Row>
            </SearchPanel>

            <SearchPanelMobile
                state={state}
                dispatch={dispatch}
                onHide={() => {
                    dispatch({type: ActionTypes.reset});
                }}
            />
        </>
    );
};
