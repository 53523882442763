import NextImage from 'next/image';
import {FormattedMessage} from 'react-intl';

import {HeroIntro} from '@components/HeroIntro';

import {Search} from '@pageComponents/HomePage/Search';
import bgImg from 'images/homepage/HP-banner-nemovitosti-prehledne-desktop.png';
import bgMobile from 'images/homepage/HP-banner-nemovitosti-prehledne-mobile.png';
import {ClientOnly} from '@liveComponents/Loadable';

export const Intro = () => (
    <HeroIntro variant="hp">
        <HeroIntro.Background gradient>
            <NextImage
                className="d-none d-sm-block"
                alt=""
                src={bgImg}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
            <NextImage
                className=" d-block d-sm-none"
                alt=""
                src={bgMobile}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </HeroIntro.Background>

        <HeroIntro.Content>
            <HeroIntro.Top>
                <HeroIntro.Heading variant="h3" center>
                    <FormattedMessage defaultMessage="Najděte svou vysněnou nemovitost" />
                </HeroIntro.Heading>

                <ClientOnly>
                    <Search />
                </ClientOnly>
            </HeroIntro.Top>
        </HeroIntro.Content>
    </HeroIntro>
);
